import CryptoJS from 'crypto-js';
import fetchJsonp from 'fetch-jsonp'

class Navitime {
  private createRequestcode() {
    return Math.random()
      .toString(32)
      .substring(2);
  }

  private createSignature(requestCode: string, signatureKey: string) {
    const hash = CryptoJS.HmacSHA256(requestCode, signatureKey);
    return hash.toString();
  }

  getMapScript() {
    const requestCode = this.createRequestcode();
    const signature = this.createSignature(
      requestCode,
      process.env.VUE_APP_NAVITIME_API_SIGNATURE_KEY
    );

    return (
      process.env.VUE_APP_NAVITIME_HOST +
      process.env.VUE_APP_NAVITIME_API_CID +
      '/v2/map_script?request_code=' +
      requestCode +
      '&signature=' +
      signature +
      '&host=' +
      location.host
    );
  }

  async getShapeCar(data: {
    start: string,
    goal: string,
    startTime: string,
    via: any
  }) {
    const requestCode = this.createRequestcode();
    const signature = this.createSignature(
      requestCode,
      process.env.VUE_APP_NAVITIME_API_SIGNATURE_KEY
    );
    const response = await fetchJsonp(encodeURI(process.env.VUE_APP_NAVITIME_HOST +
      process.env.VUE_APP_NAVITIME_API_CID +
      '/v1/shape_car?request_code=' +
      requestCode +
      '&signature=' +
      signature +
      '&start=' +
      data.start +
      '&goal=' +
      data.goal +
      '&via=' +
      JSON.stringify(data.via) +
      '&start_time=' +
      data.startTime +
      '&condition=' +
      'free_time',
    ));
    return response.json();
  }

  async getRouteCar(data: {
    start: string,
    goal: string,
    startTime: string,
    via: any
  }) {
    const requestCode = this.createRequestcode();
    const signature = this.createSignature(
      requestCode,
      process.env.VUE_APP_NAVITIME_API_SIGNATURE_KEY
    );
    const response = await fetchJsonp(encodeURI(process.env.VUE_APP_NAVITIME_HOST +
      process.env.VUE_APP_NAVITIME_API_CID +
      '/v1/route_car?request_code=' +
      requestCode +
      '&signature=' +
      signature +
      '&start=' +
      data.start +
      '&goal=' +
      data.goal +
      '&via=' +
      JSON.stringify(data.via) +
      '&start_time=' +
      data.startTime +
      '&condition=' +
      'free_time',
    ));
    return response.json();
  }

  getWebNaviUrl(data: {
    start: string,
    goal: string,
    startTime: string,
    via: any,
    specId: string
  }) {
    const requestCode = this.createRequestcode();
    const signature = this.createSignature(
      requestCode,
      process.env.VUE_APP_NAVITIME_WEBNAVI_SIGNATURE_KEY
    );

    return (encodeURI(process.env.VUE_APP_NAVITIME_HOST +
      process.env.VUE_APP_NAVITIME_WEBNAVI_CID +
      '/v1/webnavi/car?request_code=' +
      requestCode +
      '&signature=' +
      signature +
      '&start=' +
      data.start +
      '&goal=' +
      data.goal +
      '&start_time=' +
      data.startTime +
      '&via=' +
      JSON.stringify(data.via) +
      '&condition=' +
      'free_time' +
      '&spec_id=' +
      data.specId +
      '&host=' +
      location.host
    ));
  }
}

export default new Navitime();
