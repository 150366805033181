













import Vue from 'vue';
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import firebase from '@/firebase';
import Analytics from '@/utils/analytics';
import { GETTING_ON_AND_OFF_TIME } from '@/const/route';
import { routerNames } from '@/types/routerNames';
import { externalPageNames } from '@/types/externalPageNames';
import Navitime from '@/utils/navitime';

interface GeoPoint {
  latitude: number;
  longitude: number;
}

export default Vue.extend({
  props: {
    type: {
      type: String,
      required: true,
      validator: (type) => {
        return (
          type === routerNames.routeToWork || type === routerNames.routeFromWork
        );
      },
    },
    server: {
      type: Object,
      required: true,
    },
    clients: {
      type: Array,
      required: true,
    },
    afterDirectionRouted: {
      type: Function,
      required: true,
    },
    departureTime: {
      type: Date,
      required: true,
    },
  },
  data: () => ({
    totalDistance: 0,
    totalDuration: 0,
    initedMap: false,
    mapNaviUrl: '',
  }),
  computed: {
    ...mapGetters('EmployeesEmployeeModule', ['employee']),
  },
  mounted() {
    let script = document.createElement('script');
    script.setAttribute('src', Navitime.getMapScript());
    document.head.appendChild(script);
  },
  methods: {
    initMapDirUrl(origin: GeoPoint, destination: GeoPoint) {
      const via: any = [];
      this.clients.forEach((client: any) => {
        via.push({
          lat: client.geoPoint.latitude,
          lon: client.geoPoint.longitude
        });
      });
      const startTime = DateTime.fromJSDate(new Date(this.departureTime));
      this.mapNaviUrl = Navitime.getWebNaviUrl({
        start: origin.latitude + ',' + origin.longitude,
        goal: destination.latitude + ',' + destination.longitude,
        startTime: startTime.toFormat('yyyy-MM-dd') + 'T' + startTime.toFormat('HH:mm:ss'),
        via: via,
        specId: firebase.auth().currentUser?.uid ?? 'none'
      });
    },
    async initMap(
      center: GeoPoint,
      origin: GeoPoint,
      destination: GeoPoint,
      afterDirectionRouted: any
    ) {
      if (this.initedMap) {
        return;
      }
      const via: any = [];
      this.clients.forEach((client: any) => {
        via.push({
          lat: client.geoPoint.latitude,
          lon: client.geoPoint.longitude,
          name: client.name,
          'stay-time': GETTING_ON_AND_OFF_TIME / 60
        });
      });
      const startTime = DateTime.fromJSDate(new Date(this.departureTime));
      const request = {
        start: origin.latitude + ',' + origin.longitude,
        goal: destination.latitude + ',' + destination.longitude,
        startTime: startTime.toFormat('yyyy-MM-dd') + 'T' + startTime.toFormat('HH:mm:ss'),
        via: via
      };

      // ルート検索
      const r = await Navitime.getRouteCar(request);
      const routeCar = r.items[0];
      this.totalDistance = routeCar.summary.move.distance;
      this.totalDuration = routeCar.summary.move.time * 60;
      const result: any = {routes: [{legs: []}]};
      routeCar.sections.forEach((section: any) => {
        if (section.move === 'car') {
          result.routes[0].legs.push({duration: {value: section.time * 60}})
        }
      });
      afterDirectionRouted(result, this.totalDuration);

      // 地図スクリプト取得
      const map = new (window as any).mapscript.Map(
        process.env.VUE_APP_NAVITIME_API_CID,
        {
          target: '#routeMap',
          center: new (window as any).mapscript.value.LatLng(
            center.latitude,
            center.longitude
          ),
          zoomLevel: 15,
        }
      );

      // 運転者のマーカー
      map.addMarker(new (window as any).mapscript.object.Marker({
        icon: this.type === routerNames.routeToWork ? '/img/car.png' : '/img/mazda.png',
        position: new (window as any).mapscript.value.LatLng(
            origin.latitude,
            origin.longitude
        ),
      }));
      // 同乗者のマーカー
      this.clients.forEach((client: any) => {
        map.addMarker(new (window as any).mapscript.object.Marker({
          icon: '/img/client.png',
          position: new (window as any).mapscript.value.LatLng(
              client.geoPoint.latitude,
              client.geoPoint.longitude
          ),
        }));
      })
      // 勤務地のマーカー
      map.addMarker(new (window as any).mapscript.object.Marker({
        icon: this.type === routerNames.routeToWork ? '/img/mazda.png' : '/img/car.png',
        position: new (window as any).mapscript.value.LatLng(
            destination.latitude,
            destination.longitude
        ),
      }));

      // ルート形状取得
      const shape = await Navitime.getShapeCar(request)
      const figure = new (window as any).mapscript.value.GeoJsonFigureCondition(shape, {
        isRouteShape: true,
        coordUnit: 'degree', // 緯度経度の単位
        showRouteArrow: true, // ルート線描画の際に矢印をつけるか
        zIndex: 1, // 重なり順
        // ポリラインのオプション
        polyline: {
          // ポリライン内線のオプション
          inline: {
            // color: new (window as any).mapscript.value.Color(0, 0, 0.5, 1.0), // 内線の色
            weight: 8, // 先の太さ
          },
        }
      });
      const [lng1, lat1, lng2, lat2] = shape.bbox;
      map.moveBasedOnLatLngRect(
        (window as any).mapscript.util.locationsToLatLngRect([
          new (window as any).mapscript.value.LatLng(lat1, lng1),
          new (window as any).mapscript.value.LatLng(lat2, lng2),
        ]),
        true
      );
      map.addGeoJsonFigure(figure);

      this.initedMap = true;
    },
    openMapNavi() {
      Analytics.logExternalPageView(externalPageNames.mapNavi);
    },
  },
  watch: {
    server() {
      const origin = {} as GeoPoint;
      const destination = {} as GeoPoint;
      if (this.type === routerNames.routeToWork) {
        origin.latitude = this.server.geoPoint.latitude;
        origin.longitude = this.server.geoPoint.longitude;
        destination.latitude = this.server.workplaceGeoPoint.latitude;
        destination.longitude = this.server.workplaceGeoPoint.longitude;
      } else if (this.type === routerNames.routeFromWork) {
        origin.latitude = this.server.workplaceGeoPoint.latitude;
        origin.longitude = this.server.workplaceGeoPoint.longitude;
        destination.latitude = this.server.geoPoint.latitude;
        destination.longitude = this.server.geoPoint.longitude;
      }
      const center: GeoPoint = {
        latitude: this.employee.geoPoint.latitude,
        longitude: this.employee.geoPoint.longitude,
      };
      this.initMapDirUrl(origin, destination);
      setTimeout(() => {
        this.initMap(
          center,
          origin,
          destination,
          (result: any, totalDuration: number) => {
            this.afterDirectionRouted(result, totalDuration);
          }
        );
      }, 1000);
    },
  },
});
